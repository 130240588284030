import React from 'react';
//import logo from '../../images/logo.svg';
import '../../index.css';
import { Helmet } from 'react-helmet'
import { Button, Image, Placeholder } from 'semantic-ui-react'

import Quiz from '../quiz/index'
import { withFirebase } from '../firebase';

class QuizInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentQuiz: null,
            isPlay: false,
            info: null,
            loading: true,
            category: null,

            // firebase
            quizInfo: [],
            loadingQuizInfo: true,
        };
    }

    componentDidMount() {
        const { match: { params } } = this.props;

        // TEST FIREBASE
        this.props.firebase.quiz().orderByChild("slug").equalTo(params.quizName).once("child_added", (snapshot) => {
            let data = snapshot.val()
            data.key = parseInt(snapshot.key)
            
            const questionsList = Object.keys(data.questions).map(key => ({
                ...data.questions[key],
                uid: key,
            }));

            data.questions = questionsList

            this.setState({
                quizInfo: data,
                loadingQuizInfo: false
            }, () => {

                this.props.firebase.categoriesId(data.category).once("value", (snapshot) => {
                    let data = snapshot.val()
                    console.log(data)
                    this.setState({
                        category: data
                    });
                })
                
            })
        });
          
    }

    handleClickJouer = () => {
        this.setState({
            isPlay: true
        });
    }

    render() {

        return (
            <div className="quizInfo">
                <Helmet>
                    <title>Balance ta culture ! | Quiz | {`${this.state.quizInfo.title}`}</title>
                    <meta name="description" content={`Découvrez ce quiz amusant sur ${this.state.quizInfo.title}`} />

                    <meta property="og:url" content={`https://balancetaculture.fr/quiz/${this.state.quizInfo.slug}`} />
                    <meta property="og:title" content={`Balance ta culture ! | Quiz | ${this.state.quizInfo.title}`} />
                    <meta property="og:description" content="Découvrez de nombreux quiz amusants sur les arts, la géographie, la gastronomie, l'histoire, les sports et les sciences." />
                    <meta property="og:image" content={`/images/quiz/${this.state.quizInfo.picture}`} />
                    <meta property="og:image:type" content="image/jpg"/>
                    <meta property="og:image:width" content="550"/>
                    <meta property="og:image:height" content="367"/>
                    <meta property="og:type" content="website" />
                    <meta property="fb:app_id" content="" />

                    <meta property="twitter:title" content={`Balance ta culture ! | Quiz | ${this.state.quizInfo.title}`} />
                    <meta property="twitter:description" content="Découvrez de nombreux quiz amusants sur les arts, la géographie, la gastronomie, l'histoire, les sports et les sciences." />
                    <meta property="twitter:image" content={`/images/quiz/${this.state.quizInfo.picture}`} />
                    <meta property="twitter:card" content="summary" />
                </Helmet>
                <div className="ui container fluid headerInfo">
                    <div className="ui container headerTitle">
                        <h2 className="quizName">
                            <i className={`${this.state.category !== null && this.state.category.icon } icon`} size="huge"></i> 
                            {!this.state.loadingQuizInfo && (<div>{this.state.quizInfo.title}</div>)}
                            {this.state.loadingQuizInfo && (
                                <Placeholder  style={{height: 27, width: 300}} >
                                    <Placeholder.Line length='full' />
                                </Placeholder>
                            )}
                        </h2>
                    </div>
                </div>
                {!this.state.isPlay && this.state.quizInfo !== null &&
                    <div className="ui container quizCard" >
                        <div className="quizCardContent">
                            {this.state.loadingQuizInfo && (
                                <Placeholder style={{height: 367,maxWidth: '55%', width: '100%'}}>
                                    <Placeholder.Image rectangular />
                                </Placeholder>
                                )
                            }
                            { !this.state.loadingQuizInfo &&
                                <Image src={`/images/quiz/${this.state.quizInfo.picture}`} rounded/>
                            }
                            <div className="quizCardContentRight">
                                <p className="firstP">Testez vos connaissances</p>
                                <p className="secondP">Les questions s'affichent dans un ordre aléatoire. Une seule réponse est vraie pour chacune des 10 questions !</p>
                                <Button animated size="massive" color="blue" onClick={() => this.handleClickJouer()}>
                                    <Button.Content visible>Jouer</Button.Content>
                                    <Button.Content hidden>
                                        {/*<Icon name='arrow right' />*/}
                                        Go <i className={`arrow right icon`}></i>
                                    </Button.Content>
                                </Button>
                                <p className="counter" >Ce thème comporte {!this.state.loadingQuizInfo && this.state.quizInfo.questions.length } questions.</p>
                            </div>
                        </div>
                    </div>
                }
                {this.state.quizInfo !== null && this.state.isPlay &&
                    <div className="ui container text " >
                        <div className="quizGame">
                            <Quiz name={this.state.quizInfo.slug} />
                        </div>
                    </div>
                }
            </div>
        );
    }
}
export default withFirebase(QuizInfo);
