import app from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
 
const config = {
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
  };
 
class Firebase {
    
  constructor() {
    app.initializeApp(config);
    this.db = app.database();

    this.auth = app.auth()
  }
 
  categories = () => this.db.ref('category');
  categoriesId = (key) => this.db.ref('category/'+key);
  quiz = () => this.db.ref('quiz');
  quizId = (key) => this.db.ref('quiz/'+key);

  // auth
  login = (email, password) => this.auth.signInWithEmailAndPassword(email, password)
  logout = () => this.auth.signOut()
  curentUser = () => this.auth.currentUser;
  onAuthStateChanged = (onChange) => this.auth.onAuthStateChanged(onChange)

}
 
export default Firebase;