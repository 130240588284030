import React from "react";
import './index.css';

import { Progress, Placeholder, Container, Transition, Button } from 'semantic-ui-react'


import { withFirebase } from '../firebase';

class Quiz extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentQuestion: 0,
      myAnswer: null,
      myAnswerId: null,
      options: [],
      score: 0,
      disabled: true,
      isEnd: false,
      answers: [],
      maxLength: 10,
      length: 0,
      quizData: [],
      isCheck: false,
      loading: true,
      visible: [true, true, true, true],
      animationType: "shake",

      //firebase
      quizInfo: [],
      loadingAverage: true,
      quizList: [],
      loadingQuizList: true
    };
  }

  animeSuccess = (index) => {
    this.setState((prevState) => ({ 
      visible: [
        (index === 0) ? !prevState.visible[0] : prevState.visible[0], 
        (index === 1) ? !prevState.visible[1] : prevState.visible[1], 
        (index === 2) ? !prevState.visible[2] : prevState.visible[2], 
        (index === 3) ? !prevState.visible[3] : prevState.visible[3]
      ] 
    }))
  }
  handlerReset = () => {
    this.setState({ 
      currentQuestion: 0,
      isEnd: false,
      answers: [],
      myAnswer: null,
      myAnswerId: null,
      isCheck: false,
      loading: true,
      visible: [true, true, true, true],
      score: 0,
      loadingAverage: true
    }, () => {
      this.props.firebase.quiz().orderByChild("slug").equalTo(this.props.name).once("child_added", (snapshot) => {
        let data = snapshot.val()
        data.key = parseInt(snapshot.key)
  
        const questionsList = Object.keys(data.questions).map(key => ({
          ...data.questions[key],
          uid: key,
        }));
  
        data.questions = questionsList
  
        console.log(data)
        this.setState({
          quizInfo: data
        }, () => {
          this.loadQuizData()
        })
      });
    });
    
  }
 

  loadQuizData = () => {
    const { quizInfo } = this.state;

    quizInfo.questions.sort(function (a, b) { return 0.5 - Math.random() })

    this.setState(() => {
      return {
        questions: quizInfo.questions[this.state.currentQuestion].title,
        answer: quizInfo.questions[this.state.currentQuestion].answer,
        options: quizInfo.questions[this.state.currentQuestion].options.sort(function (a, b) { return 0.5 - Math.random() })
      };
    });

    if(quizInfo.questions.length < this.state.maxLength) {
        this.setState({
            length: quizInfo.questions.length,
            loading: false
        });
    } else {
        this.setState({
            length: this.state.maxLength,
            loading: false
        });
    }

  };

  componentDidMount() {
    this.props.firebase.quiz().orderByChild("slug").equalTo(this.props.name).once("child_added", (snapshot) => {
      let data = snapshot.val()
      data.key = parseInt(snapshot.key)

      const questionsList = Object.keys(data.questions).map(key => ({
        ...data.questions[key],
        uid: key,
      }));

      data.questions = questionsList

      console.log(data)
      this.setState({
        quizInfo: data
      }, () => {
        this.loadQuizData();
      })
    });
    
  }
  nextQuestionHandler = () => {
    const { myAnswer, answer, score } = this.state;

    if (myAnswer === answer) {
        this.setState({
            score: score + 1,
            answers: [...this.state.answers, myAnswer]
        });
        this.resetMyAnswer()
    } else {
        this.setState({
            answers: [...this.state.answers, myAnswer]
        });
        this.resetMyAnswer()
    }

    this.setState({
      currentQuestion: this.state.currentQuestion + 1,
      isCheck: false
    });
    
  };

  checkQuestionHandler = () => {


    this.setState({
      isCheck: true,
      animationType: (this.state.myAnswer === this.state.answer) ? "jiggle" : "shake"
    });
    this.animeSuccess(this.state.myAnswerId);
  }

  resetMyAnswer = () => {
    this.setState({
        myAnswer: null
    });
  }

  componentDidUpdate(prevProps, prevState) {


    const { quizInfo } = this.state;

    if (this.state.currentQuestion !== prevState.currentQuestion) {

      this.setState(() => {
        return {
          disabled: true,
          questions: quizInfo.questions[this.state.currentQuestion].title,
          options: quizInfo.questions[this.state.currentQuestion].options.sort(function (a, b) { return 0.5 - Math.random() }),
          answer: quizInfo.questions[this.state.currentQuestion].answer
        };
      });
    }
  }
  //check answer
  checkAnswer = (answer, index) => {
      if(!this.state.isCheck){
        this.setState({ 
          myAnswer: answer, 
          myAnswerId: index, 
          disabled: false
        });
        setTimeout(() => {
          this.checkQuestionHandler();
        }, 150);
      }
      
  };
  finishHandler = () => {
    if (this.state.currentQuestion === this.state.length - 1) {

        const { myAnswer, answer, score } = this.state;

        if (myAnswer === answer) {
            this.setState({
                score: score + 1,
                answers: [...this.state.answers, myAnswer]
            });
            this.resetMyAnswer()
        } else {
            this.setState({
                answers: [...this.state.answers, myAnswer]
            });
            this.resetMyAnswer()
        }

        // TEST FIREBASE
        var ref = this.props.firebase.quizId(this.state.quizInfo.key);
        console.log(this.state.quizInfo)
        ref.child("played").set(this.state.quizInfo.played+1)
        .then(() => {
          console.log('set played +1');
          this.setState({
            quizInfo: {
                  ...this.state.quizInfo,
                  played: this.state.quizInfo.played+1
            }
          }, () => {
            ref.child("average").set((((this.state.quizInfo.average * (this.state.quizInfo.played - 1)) + this.state.score) / (this.state.quizInfo.played)).toFixed(4))
            .then(() => {
              console.log('average setter');
              this.setState({
                quizInfo: {
                      ...this.state.quizInfo,
                      average: ((this.state.quizInfo.average * (this.state.quizInfo.played - 1)) + this.state.score) / (this.state.quizInfo.played)
                },
                loadingAverage: false
              })
            })
            .catch(function(error) {
              console.log('average setter failed');
            });
          })
        })
        .catch(function(error) {
          console.log('played setter failed');
        });

        // TEST FIREBASE
        
        this.setState({
            isEnd: true,
        });

        /* GET SIMILAR QUIZ*/
        this.props.firebase.quiz().orderByChild("category").equalTo(this.state.quizInfo.category).once("value", (snapshot) => {
          let data = snapshot.val()

          let quizList = Object.keys(data).map(key => ({
              ...data[key],
              uid: key,
          })).filter(a => parseInt(a.uid) !== this.state.quizInfo.key)

          this.setState({
              quizList: quizList,
              loadingQuizList: false,
          })
        })
        
    }
  };
  render() {
    const { isCheck, options, myAnswer, answer, currentQuestion, isEnd, quizInfo } = this.state;

    if (isEnd) {
      return (
        <div className="result">
          <p className="score">{this.state.score} / {this.state.length}</p>
          <p className="bonnes">bonnes réponses</p>
          

          { this.state.loadingAverage && 
            <div className="average">
              <Placeholder fluid>
                <Placeholder.Line length='full' />
                <Placeholder.Line length='full' />
              </Placeholder>
            </div>
          }
          { !this.state.loadingAverage && 
            <div className="average">La moyenne est de {quizInfo.average.toFixed(2)}/10 pour {quizInfo.played} participations</div>
          }

          <Button size="huge" color="blue" onClick={() => this.handlerReset()}><i className={`sync icon`}></i> Rejouer</Button>

        </div>
      );
    } else {
      return (
        <div className="App ui container">
            
            { this.state.loading &&
              <div>
                <Placeholder fluid>
                  <Placeholder.Line length='full' />
                  <Placeholder.Line length='medium' />
                </Placeholder>
                <Placeholder fluid>
                  <Placeholder.Line length='full' />
                  <Placeholder.Line length='full' />
                </Placeholder>
              </div>
            }
            { !this.state.loading &&
              <div>
                <div className="question">
                  <span>{this.state.questions}</span>
                </div>
                <Progress value={currentQuestion + 1} total={this.state.length} color='blue' >
                    Question {currentQuestion + 1} sur {this.state.length}
                </Progress>
              </div>
            }
            <Container className="options">
              <ul >
              {options.map((option, index) => (
                <li style={{display: 'block'}} key={index}>
                  <Transition
                    animation={this.state.animationType}
                    duration="500"
                    visible={this.state.visible[index]}
                  >
                    <button
                        key={option.id}
                        className={`ui button fluid large
                        ${!isCheck && myAnswer === option ? "blue" : "grey"}
                        ${isCheck && option === answer ? "green" : null}
                        ${isCheck && myAnswer === option && option !== answer ? "red" : null}
                        `}
                        onClick={() => this.checkAnswer(option, index)}
                        >
                        {option}
                    </button>
                  </Transition>
                </li>
              ))}
              </ul>
            </Container>
          {!isCheck && ( currentQuestion < this.state.length - 1 || currentQuestion === this.state.length - 1 ) && (
            <button
              className="ui button blue"
              disabled={this.state.disabled}
              onClick={this.checkQuestionHandler}
            >
              Valider
            </button>
          )}
          {isCheck && currentQuestion < this.state.length - 1 && (
            <button
              className="ui button blue"
              disabled={this.state.disabled}
              onClick={this.nextQuestionHandler}
            >
              Suivant
            </button>
          )}
          
          {isCheck && currentQuestion === this.state.length - 1 && (
            <button className="ui button blue" disabled={this.state.disabled} onClick={this.finishHandler}>
              Terminer
            </button>
          )}
        </div>
      );
    }
  }
}

export default withFirebase(Quiz);
