import React from 'react';

import './index.css';

import { Divider, Container } from 'semantic-ui-react'

export default class Footer extends React.Component {

  render() {
    return (
      <div className="Footer">
        <Container>
          <Divider horizontal><span className="footerHeader" >Balance ta culture !</span></Divider>
          <div className="companyName">
              <span>© 2020 Blance ta culture inc</span>
          </div>
        </Container>
      </div>
    );
  }
}

