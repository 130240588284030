import React from 'react';
//import logo from '../../images/logo.svg';
import './index.css';

import { Menu, Container, Dropdown } from 'semantic-ui-react'

import { Link } from 'react-router-dom'

export default class Nav extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            categories: null
        };
    }

    componentDidMount() {
        
        import(`../../data/categories.js`).then((data) => {
            this.setState({
                categories: data.categories,
                loading: false
            });
        })
          
    }

    renderCategories = () => {
        const { categories } = this.state
        return (
            categories.map((category, index) => (
                <Dropdown.Item as={ Link } to={`/category/${category.slug}`} key={category.id} >
                    <i className={`${category.icon} icon`}></i> {category.title}
                </Dropdown.Item>
            ))
        )
    }

    render() {

        return (
            <Menu secondary borderless inverted color="blue" size="huge" className="navMenu">
                <Container >
                    <Link to ="/">
                        <Menu.Item header >
                            <h1 className="title" >Balance ta culture !</h1>
                        </Menu.Item>
                    </Link>
                    <Menu.Menu position='right'>
                        <Dropdown item text='Catégories'>
                            <Dropdown.Menu>
                                {!this.state.loading &&
                                    this.renderCategories()
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </Menu.Menu>

                </Container>
            </Menu>
        );
    }
}
