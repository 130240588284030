import React from 'react';
//import logo from '../../images/logo.svg';
import '../../index.css';

import { withFirebase } from '../firebase';
import { Container, Grid, Image, Header, Placeholder } from 'semantic-ui-react';

import { Link } from 'react-router-dom'

class Home extends React.Component {

  constructor(props) {
    super(props);

    this.state = {

      quizTopInfo: null,
      loadingQuizTop: true,

      quizCategories: null,
      loadingCategories: true
    }
  }

  componentDidMount() {

    this.props.firebase.categories().orderByChild("title").once('value', data => {
      const categoryObject = data.val();
 
      if (categoryObject) {
       
        const categoryList = Object.keys(categoryObject).map(key => ({
          ...categoryObject[key],
          uid: key,
        }));

        categoryList.sort(function(a, b){
          if(a.title < b.title) { return -1; }
          if(a.title > b.title) { return 1; }
          return 0;
        })

        this.setState({
          quizCategories: categoryList,
          loadingCategories: false,
        });
      } else {
        this.setState({ quizCategories: null, loadingCategories: false });
      }
    })

    this.props.firebase.quiz().orderByChild("played").limitToLast(2).once("value", (snapshot) => {
        const data = snapshot.val()
 
        if (data) {
       
          const quizList = Object.keys(data).map(key => ({
            ...data[key],
            uid: key,
          }));

          quizList.sort(function(a, b){
            if(a.played > b.played) { return -1; }
            if(a.played < b.played) { return 1; }
            return 0;
          })

          this.setState({
              quizTopInfo: quizList,
              loadingQuizTop: false
          })
        } else {
          this.setState({ quizTopInfo: null, loadingQuizTop: false });
        }
    });

  }

  componentWillUnmount() {
  }

  render() {

    const { quizTopInfo, quizCategories } = this.state

    return (
      <div className="Home" style={{ marginTop: 32}}>

        <Container className="content">
          <div className="contentHeader">
            Testez vous sur un sujet à l'aide de nos quiz
          </div>

          <div className="contentListTop">
            <Header as="h2" content="Les tendances" />
            <Grid doubling container columns={2}>
                  { !this.state.loadingQuizTop && quizTopInfo.map((option, index) => (
                      <Grid.Column key={index}>
                          <Link className="linkToQuiz" to={`/quiz/${option.slug}`}>
                          <div  className="pickgradient ui rounded image">
                              <Image label={{
                                  as: 'span',
                                  color: 'blue',
                                  content: option.title,
                                  className: 'labelQuiz',
                                  ribbon: true,
                              }}
                              alt={option.title}
                              rounded src={`/images/quiz/${option.picture}`} />
                          </div>
                          </Link>
                      </Grid.Column>
                  ))}
 
              </Grid>
              { this.state.loadingQuizTop && (
                    <Grid doubling container columns={2}>
                      <Grid.Column>
                        <Placeholder style={{height: 357, width: '100%', maxWidth: '100%'}}>
                          <Placeholder.Image rectangular rounded="true"/>
                        </Placeholder>
                      </Grid.Column>
                      <Grid.Column>
                        <Placeholder style={{height: 357, width: '100%', maxWidth: '100%'}}>
                          <Placeholder.Image rectangular rounded="true"/>
                        </Placeholder>
                      </Grid.Column>
                    </Grid>
              )}
          </div>

          <div className="contentListCat">
            <Header as="h2" content="Les catégories" />
            <Grid doubling container columns={4}>
              { !this.state.loadingCategories && quizCategories.map((option, index) => (
                <Grid.Column key={index} >
                  <Link className="cardCatText" to={`/category/${option.slug}`}>
                    <div className="cardCat">
                      <i className={`${option.icon } icon`}></i> 
                        {option.title}
                    </div>
                  </Link>
                </Grid.Column>
              ))}
            </Grid>
            { this.state.loadingCategories && (
              <Grid doubling container columns={4}>
                <Grid.Column>
                  <div className="cardCat">
                    <Placeholder style={{height: 20, width: '100%', maxWidth: '100%'}}>
                      <Placeholder.Image rectangular rounded="true"/>
                    </Placeholder>
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div className="cardCat">
                    <Placeholder style={{height: 20, width: '100%', maxWidth: '100%'}}>
                      <Placeholder.Image rectangular rounded="true"/>
                    </Placeholder>
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div className="cardCat">
                    <Placeholder style={{height: 20, width: '100%', maxWidth: '100%'}}>
                      <Placeholder.Image rectangular rounded="true"/>
                    </Placeholder>
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div className="cardCat">
                    <Placeholder style={{height: 20, width: '100%', maxWidth: '100%'}}>
                      <Placeholder.Image rectangular rounded="true"/>
                    </Placeholder>
                  </div>
                </Grid.Column>
              </Grid>
            )}
          </div>
        </Container>

      </div>
    );
  }
}

export default withFirebase(Home)

