import React from 'react';
import '../../index.css';

import { Link, Redirect } from 'react-router-dom'

import { Grid, Image, Container, Placeholder } from 'semantic-ui-react'

import { withFirebase } from '../firebase';

class Category extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentCategory: null,
            category: [],
            info: null,
            loading: true,
            quizName: [],
            quizInfo: [],

            // firebase
            categoryInfo: [],
            loadingCategoryInfo: true,
            quizList: []
        };
    }

    componentDidMount() {
        const { match: { params } } = this.props;

        // TEST FIREBASE
        this.props.firebase.categories().orderByChild("slug").equalTo(params.categoryName).once("child_added", (snapshot) => {
            let catData = snapshot.val()
            catData.key = parseInt(snapshot.key)

            this.props.firebase.quiz().orderByChild("category").equalTo(catData.key).once("value", (snapshot) => {
                let data = snapshot.val()
                const quizList = Object.keys(data).map(key => ({
                    ...data[key],
                    uid: key,
                }));
                this.setState({
                    quizList: quizList,
                    loadingCategoryInfo: false,
                    categoryInfo: catData
                })
            })
        });
          
    }

    componentDidUpdate(prevProps, prevState) {
        const { match: { params } } = this.props;

        console.log("update")

        if(prevProps !== null 
            && prevState.categoryInfo.slug !== params.categoryName 
            && this.state.categoryInfo.slug !== params.categoryName 
            && this.state.loadingCategoryInfo === false) {

            this.setState({
                loadingCategoryInfo: true,
            })

            console.log("update control")
            
            this.props.firebase.categories().orderByChild("slug").equalTo(params.categoryName).once("child_added", (snapshot) => {
                let catData = snapshot.val()
                catData.key = parseInt(snapshot.key)
    
                
                    this.props.firebase.quiz().orderByChild("category").equalTo(catData.key).once("value", (snapshot) => {
                        let data = snapshot.val()
                        const quizList = Object.keys(data).map(key => ({
                            ...data[key],
                            uid: key,
                        }));
    
                        this.setState({
                            categoryInfo: catData,
                            quizList: quizList,
                            loadingCategoryInfo: false,
                        })
                    })
            });
        }
        

          
    }

    render() {

        const { quizList } = this.state;

        if(!this.state.loading && this.state.currentCategory === null) {

            return (
                <Redirect to={{ pathname: "/404"}} /> 
            )
        }
        return (
        <div className="Category">
            <Container>
                <h2 className="CategoryTitle"><i className={`${this.state.categoryInfo !== null && this.state.categoryInfo.icon } icon`}></i> {this.state.categoryInfo !== null && this.state.categoryInfo.title}</h2>
            </Container>
            

            { !this.state.loadingCategoryInfo && (
                <Grid doubling container columns={2}>
                    {quizList.map((option, index) => (
                        <Grid.Column key={index}>
                            <Link className="linkToQuiz" to={`/quiz/${option.slug}`}>
                            <div className="pickgradient ui image">
                                <Image label={{
                                    as: 'span',
                                    color: 'blue',
                                    content: option.title,
                                    className: 'labelQuiz',
                                    ribbon: true,
                                }}
                                src={`/images/quiz/${option.picture}`} />
                            </div>
                            </Link>
                        </Grid.Column>
                    ))}
                    
                </Grid>
            )}
            { this.state.loadingCategoryInfo && (
                <Grid doubling container columns={2}>
                    <Grid.Column>
                        <Placeholder style={{height: 357, width: '100%', maxWidth: '100%'}}>
                            <Placeholder.Image rectangular rounded/>
                        </Placeholder>
                    </Grid.Column>
                    <Grid.Column>
                        <Placeholder style={{height: 357, width: '100%', maxWidth: '100%'}}>
                            <Placeholder.Image rectangular rounded/>
                        </Placeholder>
                    </Grid.Column>
                </Grid>
              )}
        </div>
        );
    }
}
export default withFirebase(Category)
