import React from 'react'

import { Route, Redirect, Switch } from 'react-router-dom'

import Home from '../pages/Home'
import QuizInfo from '../pages/QuizInfo'
import Category from '../pages/Category';
import NotFound from '../pages/NotFound';

const Routes = () => {

    return (
        <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/category/:categoryName"  component={Category} />
            <Route path="/quiz/:quizName"  component={QuizInfo} />

            <Route path="/404" component={NotFound} />
            <Redirect to="/404" />   
        </Switch>
    )
    
}
export default Routes