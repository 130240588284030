import React from 'react';
//import logo from '../../images/logo.svg';
import '../../index.css';
import { Link } from 'react-router-dom'
import { Button } from 'semantic-ui-react'

export default class NotFound extends React.Component {

  render() {
    return (
      <div className="NotFound">
        <h2>404</h2>

        <Button as={ Link } to={`/`} size="large" color="blue">Accueil</Button>

      </div>
    );
  }
}
