import React from 'react';
//import logo from '../../images/logo.svg';
import './App.css';

import { Helmet } from 'react-helmet'

import Nav from '../nav/Nav'
import Footer from '../footer'
import Routes from '../routes'


import { BrowserRouter as Router } from "react-router-dom"

import Firebase, { FirebaseContext } from '../firebase';

import GA from '../utils/GoogleAnalytics'

const App = () => {
  return (
    <div className="App">
      <Helmet>
        <title>Balance ta culture ! | Quiz</title>
        <meta name="description" content="Découvrez de nombreux quiz amusants sur les arts, la géographie, la gastronomie, l'histoire, les sports et les sciences. Balance ta culture quiz culture générale !" />
        <meta name="keywords" content="balance ta culture,culture,quiz,balance,culture générale,culture generale,quizz" />
        {/* Og Card tags */}
        <meta property="og:url" content="https://balancetaculture.fr" />
        <meta property="og:title" content="Balance ta culture ! | Quiz" />
        <meta property="og:description" content="Découvrez de nombreux quiz amusants sur les arts, la géographie, la gastronomie, l'histoire, les sports et les sciences." />
        <meta property="og:image" content="https://balancetaculture.fr/logo512.png" />
        <meta property="og:image:type" content="image/png"/>
        <meta property="og:image:width" content="512"/>
        <meta property="og:image:height" content="512"/>
        <meta property="og:type" content="website" />
        <meta property="fb:app_id" content="" />
        {/* Twitter Card tags */}
        <meta property="twitter:title" content="Balance ta culture ! | Quiz" />
        <meta property="twitter:description" content="Découvrez de nombreux quiz amusants sur les arts, la géographie, la gastronomie, l'histoire, les sports et les sciences." />
        <meta property="twitter:image" content="https://balancetaculture.fr/logo512.png" />
        <meta property="twitter:card" content="summary" />
      </Helmet>
      <FirebaseContext.Provider value={new Firebase()}>
        
          <Router>
              { GA.init() && <GA.RouteTracker /> }
              <Nav />
              <Routes/> 
              <Footer />
          </Router>
        
      </FirebaseContext.Provider>
    </div>
  );
}

export default App;
